<template>
  <AddPlayersModal
    :team-name="data.name"
    :team-id="data.teamId"
    :sport-id="data.sportId"
    :players-to-add="playersToAdd"
    :has-table-data="hasTableData"
    :is-visible="isAddPlayersModalActive"
    @close="onModalClose"
    @onAddSelectedPlayers="addSelectedPlayers"
  />
  <div class="list-of-players">
    <div
      v-if="isPlayerSetupDisabled"
      @click.stop
      class="list-of-players-disabled-layer"
    />
    <div class="section-header">
      <div class="title">
        <p>{{ data.name }}</p>
      </div>
      <div class="actions-section">
        <Button
          v-if="hasTableData"
          :disabled="isSuspendAllPlayersDisabled || allPlayersSuspendToggleActive"
          @click="toggleAllPlayersSuspend"
          class="suspend-btn"
          :class="{'suspend-active': isSuspendAllPlayersActive}"
        >
          <Icon :name="isSuspendAllPlayersActive ? 'pause' : 'play'" />
          <span>{{ isSuspendAllPlayersActive ? 'Suspend all players' : 'Unsuspend all players' }}</span>
        </Button>
        <Button
          v-if="hasTableData"
          class="add-players-btn"
          variant="primary"
          @click="addPlayers"
        >
          <Icon name="user-plus-01" />
          <span>Players</span>
        </Button>
      </div>
    </div>
    <div
      class="section-content"
    >
      <Spinner v-if="isProjectedPlayerParamsDataLoading" />
      <div
        v-else
        class="table-data"
      >
        <div
          v-if="hasTableData"
          class="player-setup-data-container"
        >
          <PlayerParamsSingle
            v-for="playerData in playerSetupTableDataByTeamOrdered"
            :key="playerData.playerId"
            :player-data="playerData"
            :team-label="data.teamLabel"
            :is-calculated-mode="isCalculatedMode"
            :active-params="mergeParamsWithConfig(playerData)"
            :sport-id="data.sportId"
            @onParamUpdate="onParamUpdate"
            @onPlayerParamUpdate="onPlayerParamUpdate($event, playerData.playerId)"
          />
        </div>
        <div
          v-else
          class="no-data-message"
        >
          <p>
            Currently there are no players offered for this team, start by clicking on '+ Players' button.
          </p>
          <Button
            @click="addPlayers"
            variant="primary"
          >
            <Icon name="plus" />
            <span>Players</span>
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  find, filter, map, includes, reduce, omit, toUpper, orderBy,
  has, cloneDeep, findIndex, set,
} from 'lodash';
import { useStore } from 'vuex';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import sportIds from '@/services/helpers/sports';
import {
  basketballPlayerParamsSetup,
  formatPlayerName, hockeyPlayerParamsSetup,
} from '@/services/helpers/player-setup-mapper';

import {
  createBasketballPlayerParams, updateTeamPlayersPositionValue,
  createHockeyPlayerParams,
} from '@/components/player-setup/params-helper';

import {
  getSportNameBySportId,
} from '@/services/helpers/mappings-mapper';
import AddPlayersModal from './modals/AddPlayersModal';
import PlayerParamsSingle from '@/components/player-setup/common/PlayerParamsSingle';
import * as api from '@/services/api';

const {
  BASKETBALL_ID,
  HOCKEY_ID,
} = sportIds;

export default {
  components: {
    Button,
    Icon,
    AddPlayersModal,
    Spinner,
    PlayerParamsSingle,
  },
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const store = useStore();
    const isAddPlayersModalActive = ref(false);
    const data = computed(() => props.teamData);
    const isCalculatedMode = computed(() => false);
    const playerSetupTableData = computed(() => store.getters.playerSetupTableData);
    const playerSetupTableDataByTeam = computed(() => playerSetupTableData.value?.[data.value?.teamLabel]);
    const playerSetupTableDataByTeamOrdered = computed(() => orderBy(playerSetupTableDataByTeam.value, 'playerPositionIndex'));
    const isSuspendAllPlayersActive = computed(() => find(playerSetupTableDataByTeam.value, ({ isSuspended, marketCodeDetails }) => !isSuspended
      && find(marketCodeDetails, ({ isLocked }) => !isLocked)));
    const isSuspendAllPlayersDisabled = computed(() => !find(playerSetupTableDataByTeam.value,
      ({ marketCodeDetails }) => find(marketCodeDetails, ({ isLocked }) => !isLocked)));
    const hasTableData = computed(() => playerSetupTableDataByTeam.value?.length);
    const playersToAdd = computed(() => {
      const playersToExcludeIds = map(playerSetupTableDataByTeam.value, ({ playerId }) => playerId);
      const filteredPlayersList = filter(data.value.squad, ({ playerId }) => !includes(playersToExcludeIds, playerId));
      const playerList = map(filteredPlayersList, (player) => ({
        value: player.playerId,
        label: formatPlayerName(player.playerName),
        playerIndex: player.playerIndex,
      }));
      return orderBy(playerList, 'label');
    });
    const isProjectedPlayerParamsDataLoading = ref(false);
    const isPlayerSetupDisabled = computed(() => store.getters.playerSetupInferActive);

    const activePlayerParamsConfig = computed(() => {
      let playerParams;
      switch (data.value.sportId) {
      case BASKETBALL_ID:
        playerParams = createBasketballPlayerParams();
        break;
      case HOCKEY_ID:
        playerParams = createHockeyPlayerParams();
        break;
      default:
      }
      return playerParams;
    });

    const mergeParamsWithConfig = (params) => map(activePlayerParamsConfig.value, (param) => {
      const value = has(params, param.key) ? params[param.key] : 0;
      return {
        ...param,
        isError: value < param?.minValue || value > param?.maxValue,
        errorMessage: `${param?.shortName} must be a value between ${param?.minValue} and ${param?.maxValue}`,
        value,
      };
    });

    const addPlayers = () => {
      isAddPlayersModalActive.value = true;
      store.dispatch('loadTeamSquad', { teamId: data.value.teamId, sportId: data.value.sportId });
    };
    const onModalClose = () => { isAddPlayersModalActive.value = false; };

    const scrollToBottomOfTable = () => {
      const tableScroller = document.getElementById(`${data.value.teamLabel}-table--table-scroller`);
      if (tableScroller) {
        tableScroller.scrollTo({
          top: tableScroller.scrollHeight,
          behavior: 'smooth',
        });
      }
    };

    const addSelectedPlayers = async (addedPlayers) => {
      isAddPlayersModalActive.value = false;
      let playerParams;
      const side = toUpper(props.teamData?.label);
      switch (data.value.sportId) {
      case BASKETBALL_ID:
        playerParams = basketballPlayerParamsSetup({ side });
        break;
      case HOCKEY_ID:
        playerParams = hockeyPlayerParamsSetup({ side });
        break;
      default:
      }
      playerParams = {
        ...playerParams.playerParams,
        marketCodeDetails: playerParams.marketCodeDetails,
        isSuspended: playerParams.isSuspended,
      };
      let projectedPlayerParams = [];
      isProjectedPlayerParamsDataLoading.value = true;
      try {
        const paramsData = await api.getProjectedPlayerParams({
          sport: getSportNameBySportId(data.value.sportId).toLowerCase(),
          eventId: props.eventId,
          addedPlayers,
        });
        projectedPlayerParams = reduce(
          paramsData.projectedPlayerParams,
          (allPlayers, player) => ({
            ...allPlayers,
            [player.playerParams?.playerId]: player.playerParams,
          }), {},
        );
      } catch {
        projectedPlayerParams = null;
      }
      isProjectedPlayerParamsDataLoading.value = false;

      let nextPlayerIndex = orderBy(playerSetupTableDataByTeam.value, 'playerPositionIndex')[playerSetupTableDataByTeam.value?.length - 1]?.playerPositionIndex || 0;
      const addedPlayersProjectedParams = reduce(
        addedPlayers,
        (allPlayers, playerId) => {
          nextPlayerIndex += 1;
          const playerInfo = find(data.value.squad, { playerId });
          // Here we loop through the list of added players IDs
          // 1. playerParams
          // All the player params with default values as a fallback
          // 2. playerInfo
          // We add playerInfo which contains playerName and playerIndex which we need
          // 3. projectedPlayerParams
          // Player params projections fetched from an API
          return [
            ...allPlayers,
            ...[{
              ...playerParams,
              ...playerInfo,
              ...(projectedPlayerParams ? omit(projectedPlayerParams[playerId], ['marketCodeDetails']) : {}),
              lineup: true,
              playerPositionIndex: nextPlayerIndex,
            }],
          ];
        },
        [],
      );
      const updatedTeamData = [
        ...(playerSetupTableDataByTeam.value ? playerSetupTableDataByTeam.value : []),
        ...addedPlayersProjectedParams,
      ];

      const playerSetupTableDataPayload = {
        ...playerSetupTableData.value,
        [data.value.teamLabel]: updatedTeamData,
      };
      store.dispatch('setPlayerSetupTableData', playerSetupTableDataPayload).then(() => {
        scrollToBottomOfTable();
      });
    };

    const allPlayersSuspendToggleActive = computed(() => store.getters.allPlayersSuspendToggleActive);
    const toggleAllPlayersSuspend = () => {
      const action = isSuspendAllPlayersActive.value ? 'suspend' : 'unsuspend';
      store.dispatch('toggleAllPlayersSuspend', {
        teamLabel: data.value.teamLabel,
        action,
      });
    };

    const onPlayerParamUpdate = ({ newVal, param }, playerId) => {
      const playersDataUpdated = cloneDeep(playerSetupTableData.value);
      let playerDataByTeamUpdated = cloneDeep(playerSetupTableDataByTeam.value);
      const updatedRow = find(playerDataByTeamUpdated, { playerId });
      const updatedPlayerIndex = findIndex(playerDataByTeamUpdated, (player) => player.playerId === updatedRow.playerId);

      if (param.key === 'playerPositionIndex') {
        playerDataByTeamUpdated = updateTeamPlayersPositionValue(playerDataByTeamUpdated, playerId, newVal, playerDataByTeamUpdated[updatedPlayerIndex]?.playerPositionIndex);
      }

      set(updatedRow, param.key, newVal);
      playerDataByTeamUpdated[updatedPlayerIndex] = updatedRow;
      playersDataUpdated[data.value.teamLabel] = playerDataByTeamUpdated;

      store.dispatch('setPlayerSetupTableData', playersDataUpdated);
    };

    const onParamUpdate = ({ value, paramKey, playerId }) => {
      const playersDataUpdated = cloneDeep(playerSetupTableData.value);
      const playerDataByTeamUpdated = cloneDeep(playerSetupTableDataByTeam.value);
      const updatedRow = find(playerDataByTeamUpdated, { playerId });
      const updatedPlayerIndex = findIndex(playerDataByTeamUpdated, (player) => player.playerId === updatedRow.playerId);

      set(updatedRow, paramKey, value);
      playerDataByTeamUpdated[updatedPlayerIndex] = updatedRow;
      playersDataUpdated[data.value.teamLabel] = playerDataByTeamUpdated;

      store.dispatch('setPlayerSetupTableData', playersDataUpdated);
    };
    return {
      data,
      hasTableData,
      isAddPlayersModalActive,
      playersToAdd,
      isProjectedPlayerParamsDataLoading,
      isPlayerSetupDisabled,
      isSuspendAllPlayersActive,
      isSuspendAllPlayersDisabled,
      playerSetupTableDataByTeamOrdered,
      isCalculatedMode,
      allPlayersSuspendToggleActive,
      toggleAllPlayersSuspend,
      addPlayers,
      onModalClose,
      addSelectedPlayers,
      mergeParamsWithConfig,
      onPlayerParamUpdate,
      onParamUpdate,
    };
  },
};
</script>

<style lang="scss">
.list-of-players {
  height: 50%;
  padding-top: 16px;
  position: relative;
  .list-of-players-disabled-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    z-index: 120;
  }
  .section-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: $primary500;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding-right: 3px;
    margin-bottom: 12px;
    .title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
  .actions-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .suspend-btn {
      &.suspend-active {
        color: $error500;

        .icon {
          svg path {
            fill: $error500;
          }
        }
      }
    }
    .add-players-btn {
      .icon {
        svg path {
          fill: $white;
        }
      }
    }
  }
  .section-content {
    height: calc(100% - 55px);
    .table-data {
      height: 100%;
      .player-setup-data-container {
        height: 100%;
        overflow-y: auto;
      }
      .no-data-message {
        width: 55%;
        margin: 0 auto;
        text-align: center;
        padding: 32px 0;
        p {
          line-height: 17px;
          text-align: center;
          color: $gray700;
        }
        .button {
          margin-top: 16px;
          span {
            font-size: 14px;
            line-height: 17px;
          }
          .icon {
            svg path {
              stroke: $white;
            }
          }
        }
      }
    }
  }
}
</style>
